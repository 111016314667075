import { FormEvent, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useLocation } from 'react-router';
import AgoraIdField from 'components/agora/InputAgora';
import Title from 'components/content/Title';
import Message from 'components/message/Message';
import Form from 'components/layout/form/Form';
import ContentButton from 'components/content/ContentButton';
import { Button } from '@agora-care/ui-components';
import styles from './FormAgora.module.scss';

type MessageType = {
  type: 'error' | 'info' | 'success' | 'warning';
  message: string;
} | undefined;

function FormAgora() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { hash } = useLocation();
  const [agoraID, setAgoraID] = useState<string>('');

  const handleSubmit = async (e: FormEvent) => {
    e.preventDefault();

    if (/^\d{4}-\d{3}-\d{4}$/.test(agoraID)) {
      navigate(`/${agoraID}`);
    }
  };

  const handleWithoutAgoraID = () => {
    navigate('/enrollment');
  };

  const message: MessageType = (() => {
    if (hash === '#unknownuser') {
      return {
        type: 'error',
        message: t('agora.unknownuser'),
      };
    }

    if (hash === '#usercreated') {
      return {
        type: 'success',
        message: t('agora.usercreated'),
      };
    }

    return undefined;
  })();

  return (
    <Form onSubmit={handleSubmit}>
      <div className={styles.form}>
        <Title title={t('agora.enteruserid')} />
        <AgoraIdField
          label={t('agora.agoracareid')}
          onChange={(value) => {
            setAgoraID(value);
          }}
        />
        {message && (
          <Message
            type={message.type}
            message={message.message}
          />
        )}
        <ContentButton>
          <Button
            variant='text'
            color='neutral'
            onClick={handleWithoutAgoraID}
          >
            {t('agora.withoutagoraid')}
          </Button>
          <Button type='submit'>
            {t('agora.validate')}
          </Button>
        </ContentButton>
      </div>
    </Form>
  );
}

export default FormAgora;
