import { useEffect, useRef } from 'react';
import { retrieveInstitutionLogo } from 'api/institutionProxy';
import styles from './InstitutionLogo.module.scss';

type InstitutionLogoProps = {
  institutionID: string;
  className?: string;
};

function InstitutionLogo(props: InstitutionLogoProps) {
  const { institutionID, className } = props;
  const imageRef = useRef<HTMLImageElement>(null);

  useEffect(() => {
    const setInstitutionLogo = async () => {
      if (window.authService !== undefined) {
        const image = imageRef.current;
        const staffToken = await window.authService.getAccessToken();
        const user = await window.authService.getUser();
        if (
          user !== null
          && user !== undefined
          && user.profile !== undefined
          && user.profile.institution !== undefined
        ) {
          const logo = await retrieveInstitutionLogo(user.profile.institution as string, staffToken);
          if (image) {
            image.onload = () => {
              URL.revokeObjectURL(image.src);
            };

            image.src = URL.createObjectURL(logo);
          }
        }
      }
    };

    setInstitutionLogo();
  }, []);

  return (
    <div className={`${styles.wrapper} ${className}`}>
      <img
        ref={imageRef}
        className={styles.logo}
        alt={institutionID}
      />
    </div>
  );
}

export default InstitutionLogo;
