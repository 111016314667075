import { ReactNode } from 'react';
import AgoraSidebar from 'components/layout/agoraSidebar/AgoraSidebar';
import useBreakpoint from 'hooks/useBreakpoint';
import AgoraTopbar from 'components/layout/agoraTopbar/AgoraTopbar';
import AccountCredentialsUpdate from 'components/accountCredentialsUpdate/AccountCredentialsUpdate';
import styles from './MainContent.module.scss';

type Props = {
  className?: string,
  children: ReactNode,
}

function MainContent({ className = '', children }: Props) {
  const breakpoint = useBreakpoint();

  return (
    <div className={`${styles.wrapper} ${className}`}>
      <div className={styles.appbar} />
      <div className={styles.sidebar}>
        {breakpoint.down('sm') ? (
          <AgoraTopbar />
        ) : (
          <AgoraSidebar />
        )}
      </div>
      <main className={styles.main}>
        {children}
      </main>
      <AccountCredentialsUpdate />
    </div>
  );
}

export default MainContent;
