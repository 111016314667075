import { ReactNode } from 'react';
import styles from './Stack.module.scss';

interface Props {
  children: ReactNode,
  numberOfColumns?: 'auto-columns' | 'two-columns' | 'three-columns' | 'four-columns',
  width?: 'large' | 'medium' | 'small',
  alignment?: 'left' | 'right',
}

function Stack({
  children,
  numberOfColumns = 'auto-columns',
  width = 'large',
  alignment = undefined,
}: Props) {
  const classNames = [styles.wrapper];

  if (numberOfColumns) {
    classNames.push(styles[numberOfColumns]);
  }

  if (width) {
    classNames.push(styles[width]);
  }

  if (alignment) {
    classNames.push(styles[alignment]);
  }

  return (
    <div className={classNames.join(' ')}>
      {children}
    </div>
  );
}

export default Stack;
