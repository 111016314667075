import { ReactNode } from 'react';
import styles from './ContentButton.module.scss';

type Props = {
  className?: string,
  children: ReactNode,
}

function ContentButton({ className = '', children }: Props) {
  return (
    <div className={`${styles.wrapper} ${className}`}>
      {children}
    </div>
  );
}

export default ContentButton;
