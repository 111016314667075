import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router';
import PageLoader from 'components/layout/pageLoader/PageLoader';
import UpdateUser from './UpdateUser';
import { isTemporaryUser, loadUser, UserRegistered } from './UserRegistred';
import Content from '../content/Content';

interface Props {
  institutionID: string,
}

function LayoutEditUser({ institutionID }: Props) {
  const navigate = useNavigate();
  const { agoraID } = useParams();
  const [loadingUser, setLoadingUser] = useState(true);
  const [user, setUser] = useState<UserRegistered>();

  useEffect(() => {
    const getUser = async (userAgoraID: string) => {
      try {
        const userLoaded = await loadUser(userAgoraID, institutionID);
        if (userLoaded !== undefined && !isTemporaryUser(userLoaded)) {
          setUser(userLoaded);
          setLoadingUser(false);
        } else {
          navigate('/');
        }
      } catch {
        navigate('/');
      }
    };

    if (agoraID !== undefined) {
      getUser(agoraID);
    } else {
      navigate('/');
    }
  }, []);

  return (
    (!loadingUser && user !== undefined && agoraID !== undefined) ? (
      <Content>
        <UpdateUser
          agoraID={agoraID}
          institutionID={institutionID}
          user={user}
          onCancel={() => navigate(`/${agoraID}/user`)}
          onUpdate={(userUpdated) => {
            setUser(userUpdated);
            navigate(`/${agoraID}/user`);
          }}
        />
      </Content>
    ) : (
      <PageLoader />
    )
  );
}

export default LayoutEditUser;
