import drawerStyles from './Drawer.module.scss';

interface DrawerProps {
  children: React.ReactNode[] | React.ReactNode;
}

function Drawer(props: DrawerProps) {
  const { children } = props;

  return (
    <div className={drawerStyles.wrapper}>
      {children}
    </div>
  );
}

export default Drawer;
