import { useTranslation } from 'react-i18next';
import './InputRequestType.css';
import InputRadio from 'components/input/InputRadio';
import { RequestType } from '../../api/healthcareProvider';
import styles from './InputRequestType.module.scss';

export type RadioValues = 'none' | RequestType;

interface Props {
  value: RadioValues,
  className?: string,
  onChange?: (value: RadioValues) => void,
}

function InputRequestType({
  value = 'none',
  className = '',
  onChange,
}: Props) {
  const { t } = useTranslation();

  const handleChange = (requestType: RadioValues) => {
    if (onChange !== undefined) {
      onChange(requestType);
    }
  };

  return (
    <div className={`${styles.wrapper} ${className}`}>
      <span className={styles.label}>{t('useraffiliation.transfer')}</span>
      <div className={styles.list}>
        <InputRadio
          className={styles.container}
          id='request_type_none'
          label={t('useraffiliation.sendnothing')}
          value='none'
          name='request_type'
          checked={value === 'none'}
          onChange={(v) => handleChange(v as RadioValues)}
        />
        <InputRadio
          className={styles.container}
          id='request_type_day'
          label={t('useraffiliation.dayexam')}
          value='day'
          name='request_type'
          checked={value === 'day'}
          onChange={(v) => handleChange(v as RadioValues)}
        />
        <InputRadio
          className={styles.container}
          id='request_type_full_history'
          label={t('useraffiliation.fullhistoryexam')}
          value='full_history'
          name='request_type'
          checked={value === 'full_history'}
          onChange={(v) => handleChange(v as RadioValues)}
        />
      </div>
    </div>
  );
}

export default InputRequestType;
