import { ReactNode } from 'react';
import './Title.css';

type Props = {
  title: string,
  className?: string,
  children?: ReactNode,
}

function Title(props: Props) {
  const { title, className = '', children = null } = props;

  return (
    <div
      className={`${className} content-title`}
    >
      <div className='title'>
        {title}
      </div>
      {children}
    </div>
  );
}

export default Title;
