import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Message from 'components/message/Message';
import { Button } from '@agora-care/ui-components';
import InputArrayItem from './InputArrayItem';
import InputCheckBox from './InputCheckBox';
import styles from './InputArray.module.scss';

interface Props {
  label: string,
  id: string,
  values: Array<string>,
  errorMessage?: string,
  type?: 'input' | 'email' | 'number' | 'password' | 'tel' | 'text',
  required?: boolean,
  disabled?: boolean,
  deactivatable?: boolean,
  deactivatableText?: string,
  className?: string,
  onUpdate?: (items: Array<string>) => void,
  onDisabled?: (disabled: boolean) => void,
}

function InputArray({
  errorMessage = '',
  id,
  label,
  values,
  type = 'text',
  required = false,
  disabled = false,
  deactivatable = false,
  deactivatableText = '',
  className = '',
  onUpdate,
  onDisabled,
}: Props) {
  const [value, setInputValue] = useState<string>('');
  const [focusOut, setFocusOut] = useState<boolean>(false);
  const [pastedValue, setPasted] = useState<boolean>(false);
  const { t } = useTranslation();
  const requiredMessage: string = t('input.requiredfield');

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    if (value.length > 0) {
      setFocusOut(false);
    }
    if (pastedValue === true) {
      setInputValue(event.target.value.trim());
      setPasted(false);
    } else {
      setInputValue(event.target.value);
    }
  };

  const handlePaste = (event: React.ClipboardEvent<HTMLInputElement>): void => {
    if (event.clipboardData !== null) {
      setPasted(true);
    }
  };

  const handleBlur = (): void => {
    if (values.length === 0 && value.length === 0) {
      setFocusOut(true);
    }
  };

  const handleUpdate = (items: Array<string>) => {
    if (onUpdate !== undefined) {
      onUpdate(items);
    }
  };

  const handleAdd = () => {
    setInputValue('');
    if (
      value.length > 0
      && !values.includes(value)
    ) {
      handleUpdate([...values, value]);
    }
  };

  const handleDisabled = (checked: boolean) => {
    if (onDisabled !== undefined) {
      onDisabled(checked);
    }
  };

  const items = values.map((itemValue, itemIndex) => (
    <InputArrayItem
      key={itemValue}
      value={itemValue}
      onRemove={() => {
        const newValues = values.filter((_, index) => index !== itemIndex);
        handleUpdate(newValues);
      }}
      disabled={disabled}
    />
  ));

  const handleEnterDown = (event: React.KeyboardEvent) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      handleAdd();
    }
  };

  return (
    <div className={`${styles.wrapper} ${className} ${(errorMessage.length > 0 && !disabled) && styles.error}`}>
      {
        deactivatable && (
          <InputCheckBox
            id={`input-array-checkbox-${id}`}
            value={deactivatableText}
            checked={disabled}
            onChange={handleDisabled}
            className={styles.desactivatable}
          />
        )
      }
      <input
        type={type}
        id={id}
        value={value}
        onKeyDown={handleEnterDown}
        onChange={handleChange}
        onPaste={handlePaste}
        onBlur={handleBlur}
        className={styles.input}
        autoComplete='off'
        data-testid='input-fields'
        disabled={disabled}
      />
      <label
        htmlFor={id}
        data-testid='label-fields'
        className={styles.label}
      >
        {label}
      </label>
      <Button
        className={styles.button}
        onClick={handleAdd}
        disabled={disabled}
        variant='outlined'
        color='neutral'
      >
        {t('input.add')}
      </Button>
      {
        !disabled && (
          <>
            {
              (required && focusOut && errorMessage.length === 0) && (
                <Message
                  type='warning'
                  message={requiredMessage}
                  className={styles.message}
                />
              )
            }
            {
              errorMessage && (
                <Message
                  type='error'
                  message={errorMessage}
                  className={styles.message}
                />
              )
            }
            {
              (values.length > 0 && disabled === false) && (
                <div className={styles.items}>
                  { items }
                </div>
              )
            }
          </>
        )
      }
    </div>
  );
}

export default InputArray;
