import React, { useState, useEffect, useContext } from 'react';
import {
  BrowserRouter as Router, Routes, Route,
} from 'react-router';
import LayoutFormAgora from 'components/agora/LayoutFormAgora';
import LayoutViewUser from 'components/user/LayoutViewUser';
import { getUser } from 'api/user';
import { EnrollerContext } from 'context/enroller/Enroller';
import { KeyPairContext } from 'context/keypair/KeyPair';
import { InstitutionsContext } from 'context/institutions/Institutions';
import LayoutUser from '../user/LayoutUser';
import LayoutRegistration from '../registration/LayoutRegistration';
import LayoutEditUser from '../user/LayoutEditUser';
import MainContent from '../content/MainContent';
import LayoutUserAffiliation from '../useraffiliation/LayoutUserAffiliation';
import OidcSignin from '../oidc/OidcSignin';

function App() {
  const [agoraID, setAgoraID] = useState<string>('');
  const [institutionID, setInstitutionID] = useState<string>('');
  const { setEnroller } = React.useContext(EnrollerContext);
  const { setKeyPair, publicJWK, privateKey } = useContext(KeyPairContext);
  const { initializeInstitutions } = React.useContext(InstitutionsContext);

  useEffect(() => {
    if (
      agoraID.length > 0
      && (
        publicJWK === undefined
        || privateKey === undefined
      )
    ) {
      setKeyPair();
    }
  }, [agoraID]);

  useEffect(() => {
    const setInstitutionsList = async (token: string) => {
      initializeInstitutions(token);
    };

    if (
      agoraID.length > 0
      && window.authService !== undefined
    ) {
      const token = window.authService.getAccessToken();
      setInstitutionsList(token);
    }
  }, [agoraID]);

  const getEnrollerPasswordStatus = async (agoraId: string) => {
    try {
      if (window.authService !== undefined) {
        const token = window.authService.getAccessToken();
        if (token !== undefined) {
          const user = await getUser(agoraId, token);

          return user.temporary_password;
        }
      }

      return false;
    } catch {
      return false;
    }
  };

  return (
    <OidcSignin
      onProfileLoaded={async (profile) => {
        setAgoraID(profile.agoraID);
        setInstitutionID(profile.institutionID);
        setEnroller({
          agoraID: profile.agoraID,
          institutionID: profile.institutionID,
          initiale: profile.name && profile.name.split(' ').map((v) => v[0]).join(''),
          locale: profile.locale,
          hasToChangePassword: await getEnrollerPasswordStatus(profile.agoraID),
        });
      }}
    >
      <Router>
        {(agoraID.length > 0 && institutionID.length > 0) && (
          <MainContent>
            <Routes>
              <Route
                path='/:agoraID/edit'
                element={(
                  <LayoutEditUser
                    institutionID={institutionID}
                  />
                  )}
              />
              <Route
                path='/:agoraID/user'
                element={(
                  <LayoutViewUser
                    institutionID={institutionID}
                  />
                  )}
              />
              <Route
                path='/:agoraID/enrollment'
                element={(
                  <LayoutRegistration
                    institutionID={institutionID}
                  />
                  )}
              />
              <Route
                path='/:agoraID/affiliation'
                element={(
                  <LayoutUserAffiliation />
                  )}
              />
              <Route
                path='/:agoraID'
                element={(
                  <LayoutUser />
                  )}
              />
              <Route
                path='/enrollment'
                element={(
                  <LayoutRegistration
                    institutionID={institutionID}
                    isPaperlessWithoutAgoraID
                  />
                  )}
              />
              <Route
                path='/'
                element={(
                  <LayoutFormAgora />
                  )}
              />
            </Routes>
          </MainContent>
        )}
      </Router>
    </OidcSignin>
  );
}

export default App;
