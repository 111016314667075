import { ReactNode } from 'react';
import styles from './content.module.scss';

type Props = {
  className?: string,
  children: ReactNode,
}

function Content(props: Props) {
  const { className = '', children } = props;

  return (
    <div className={`${className} ${styles.content}`}>
      {children}
    </div>
  );
}

export default Content;
