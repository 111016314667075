import { useEffect, useRef } from 'react';
import styles from './Loader.module.scss';

interface Props {
  size?: number,
  className?: string,
}

function Loader({
  size = 50,
  className = '',
}: Props) {
  const refLoader = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (refLoader.current) {
      refLoader.current.style.width = `${size}px`;
      refLoader.current.style.height = `${size}px`;
    }
  }, [size]);

  return (
    <div
      ref={refLoader}
      className={`${styles.loader} ${className}`}
    />
  );
}

export default Loader;
