import { useState, useEffect, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import Message from 'components/message/Message';
import { Button } from '@agora-care/ui-components';
import { KeyPairContext } from 'context/keypair/KeyPair';
import InputFields from '../input/InputFields';
import { getPublicKeys, postEncryptedPID, UserInformation } from '../../api/patientDemographicQuery';
import { PublicKeys } from '../../services/Encrypt';
import styles from './SearchByPID.module.scss';

interface Props {
  onFinding(userInformation: UserInformation): void,
  className?: string,
}

function SearchByPID({
  onFinding,
  className = '',
}: Props) {
  const { t } = useTranslation();
  const [value, setValue] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<boolean>(false);
  const { publicJWK, privateKey } = useContext(KeyPairContext);
  const [publicKeys, setPublicKeys] = useState<PublicKeys | undefined>(undefined);
  const disabled = publicJWK === undefined || publicKeys === undefined;

  useEffect(() => {
    const retrievePublicKey = async () => {
      if (
        window !== undefined
        && window.authService !== undefined
      ) {
        const staffToken = await window.authService.getAccessToken();
        const publicKeysRetrieved = await getPublicKeys(staffToken);
        if (publicKeysRetrieved.length > 0) {
          setPublicKeys(publicKeysRetrieved);
        }
      }
    };

    retrievePublicKey();

    return () => {};
  }, []);

  const handleSearchClick = async () => {
    try {
      setLoading(true);
      setError(false);
      if (
        window.authService !== undefined
        && publicKeys !== undefined
        && publicJWK !== undefined
        && privateKey !== undefined
      ) {
        const token = window.authService.getAccessToken();
        const pid = value.trim();
        const userInformation = await postEncryptedPID(publicKeys, publicJWK, privateKey, pid, token);
        if (userInformation !== null) {
          onFinding(userInformation);
        }
      }
      setLoading(false);
    } catch {
      setError(true);
      setLoading(false);
    }
  };

  const handleChangeValue = (targetValue: string) => {
    setValue(targetValue);
  };

  return (
    <div
      className={`${className} ${styles.wrapper}`}
    >
      <InputFields
        label={t('searchbypid.searchbypid')}
        id='input-search-by-pid'
        className={styles.input}
        value={value}
        onChange={handleChangeValue}
        disabled={disabled || loading}
        isLoading={loading}
      />
      <Button
        variant='outlined'
        color='neutral'
        onClick={handleSearchClick}
        className={styles.button}
        disabled={disabled}
      >
        {t('searchbypid.search')}
      </Button>
      {error && (
        <Message
          type='error'
          message={t('searchbypid.error')}
          className={styles.error}
        />
      )}
    </div>
  );
}

export default SearchByPID;
