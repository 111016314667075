import { ReactNode } from 'react';
import styles from './InputContent.module.scss';

type Props = {
  className?: string,
  children: ReactNode,
}

function InputContent({ className = '', children }: Props) {
  return (
    <div
      className={`${className} ${styles.content}`}
    >
      {children}
    </div>
  );
}

export default InputContent;
