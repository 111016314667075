import { FormEvent, ReactNode } from 'react';
import styles from './Form.module.scss';

interface Props {
  children?: ReactNode,
  onSubmit?: (e: FormEvent) => void,
}

function Form({ children, onSubmit }: Props) {
  const handleOnSubmit = (e: FormEvent) => {
    if (onSubmit) {
      onSubmit(e);
    }
  };

  return (
    <form
      className={styles.wrapper}
      onSubmit={handleOnSubmit}
    >
      {children}
    </form>
  );
}

export default Form;
