import { useTranslation } from 'react-i18next';
import styles from './Message.module.scss';

type MessageProps = {
  className?: string;
  type?: 'error' | 'success' | 'warning' | 'info';
  message?: string | string[];
};

function Message({
  className = '',
  type = 'warning',
  message = undefined,
}: MessageProps) {
  const { t } = useTranslation();

  return (
    <div className={`${styles.wrapper} ${styles[type]} ${className}`}>
      <div className={styles.message}>
        {Array.isArray(message) ? (
          message.map((m) => (
            <p key={m}>{m}</p>
          ))
        ) : (
          <p>{message === undefined ? t('content.error') : message }</p>
        )}
      </div>
    </div>
  );
}

export default Message;
