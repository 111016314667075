import React from 'react';
import './InputCheckBox.css';

interface Props {
  id: string,
  value: string,
  className?: string,
  checked: boolean,
  onChange: (checked: boolean) => void,
}

function InputCheckBox({
  id,
  value,
  onChange,
  checked,
  className = '',
}: Props) {
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    onChange(event.target.checked);
  };

  return (
    <label
      htmlFor={id}
      className={`${className} input-checkbox`}
    >
      <input
        type='checkbox'
        id={id}
        name='disable-input-array'
        onChange={handleChange}
        checked={checked}
      />
      <span>
        { value }
      </span>
    </label>
  );
}

export default InputCheckBox;
