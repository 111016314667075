/* eslint-disable camelcase */
import React, { useState } from 'react';
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import './FormUserRequest.css';
import Form from 'components/layout/form/Form';
import InputContent from 'components/content/InputContent';
import CardWrapper from 'components/layout/cardWrapper/CardWrapper';
import Message from 'components/message/Message';
import Stack from 'components/layout/stack/Stack';
import { Button } from '@agora-care/ui-components';
import ContentButton from '../content/ContentButton';
import Title from '../content/Title';
import postActionRequest, { isErrorActionRequest } from '../../api/healthcareProvider';
import InputArray from '../input/InputArray';
import InputRequestType, { RadioValues } from './InputRequestType';

interface Props {
  name: string,
  agoraID: string,
  onCancel?: (event: React.MouseEvent<HTMLButtonElement>) => void,
  onSubmit?: () => void,
}

function FormUserRequest({
  name,
  agoraID,
  onCancel,
  onSubmit,
}: Props) {
  const [loading, setLoading] = useState<boolean>(false);
  const [pids, setPids] = useState<Array<string>>([]);
  const [requestType, setRequestType] = useState<RadioValues>('none');
  const [error, setError] = useState<string>('');
  const { t } = useTranslation();

  const sendActionRequest = async () => {
    if (window.authService !== undefined) {
      const token = await window.authService.getAccessToken();
      if (token !== undefined) {
        const paramRequestType = requestType === 'none' ? undefined : requestType;
        await postActionRequest(agoraID, token, paramRequestType, pids);
      } else {
        throw new Error('unable to get a token');
      }
    } else {
      throw new Error('authService undefined');
    }
  };

  const handleSubmit = async (e: React.FormEvent) => {
    try {
      e.preventDefault();
      setLoading(true);
      setError('');
      await sendActionRequest();
      if (onSubmit !== undefined) {
        onSubmit();
      }
      setLoading(false);
    } catch (err) {
      if (
        axios.isAxiosError(err)
        && err.response
        && err.response.data
        && isErrorActionRequest(err.response.data)
        && err.response.data.code === 'actionAlreadyCreated'
      ) {
        setError(t('useraffiliation.requestinprogress'));
      } else {
        setError(t('useraffiliation.error'));
      }
      setLoading(false);
    }
  };

  const handleCancel = (event: React.MouseEvent<HTMLButtonElement>) => {
    if (onCancel !== undefined) {
      onCancel(event);
    }
  };

  return (
    <div className='wrapper'>
      <CardWrapper>
        <Form onSubmit={handleSubmit}>
          <Title
            title={t('useraffiliation.request', { name })}
          />
          <InputContent>
            <>
              <Stack>
                <InputRequestType
                  value={requestType}
                  onChange={(requestTypeUpdated) => {
                    setRequestType(requestTypeUpdated);
                  }}
                />
              </Stack>
              <Stack>
                <InputArray
                  label={t('useraffiliation.pid')}
                  id='pid'
                  values={pids}
                  onUpdate={(pidsUpdated) => {
                    setPids(pidsUpdated);
                  }}
                />
              </Stack>
              {error.length > 0 && (
              <Message
                type='error'
                message={error}
              />
              )}
            </>
          </InputContent>
          <ContentButton>
            <Button
              variant='text'
              color='neutral'
              onClick={handleCancel}
            >
              {t('useraffiliation.cancel')}
            </Button>
            <Button
              type='submit'
              disabled={loading}
            >
              {t('useraffiliation.validate')}
            </Button>
          </ContentButton>
        </Form>
      </CardWrapper>
    </div>
  );
}

export default FormUserRequest;
