import React, { useState } from 'react';
import 'react-phone-number-input/style.css';
import { useTranslation } from 'react-i18next';
import PhoneInput from 'react-phone-number-input';
import { E164Number } from 'libphonenumber-js';
import { EnrollerContext } from 'context/enroller/Enroller';
import Message from 'components/message/Message';
import en from 'react-phone-number-input/locale/en.json';
import fr from 'react-phone-number-input/locale/fr.json';
import de from 'react-phone-number-input/locale/de.json';
import it from 'react-phone-number-input/locale/it.json';
import styles from './PhoneInputField.module.scss';

interface Props {
  id: string,
  label: string,
  value?: string,
  errorMessage: string,
  required?: boolean,
  className?: string,
  onChange?: (value: string) => void,
}

function RegistrationPhoneInput({
  id,
  label,
  value = '',
  required = false,
  className,
  errorMessage = '',
  onChange,
}: Props) {
  const [focusOut, setFocusOut] = useState(false);
  const { t } = useTranslation();
  const { enroller } = React.useContext(EnrollerContext);

  const defaultLocale = 'fr-CH';
  const availableLocales = {
    'en-GB': en,
    'fr-CH': fr,
    'de-CH': de,
    'it-CH': it,
  };

  const requiredMessage: string = t('input.requiredfield');

  const handleChange = (valueChange: E164Number | undefined) => {
    if (valueChange === undefined || valueChange.length > 0) {
      setFocusOut(false);
    }

    if (onChange !== undefined) {
      onChange(String(valueChange === undefined ? '' : valueChange));
    }
  };

  const handleBlur = () => {
    if (value.length === 0) {
      setFocusOut(true);
    }
  };

  return (
    <div
      className={`${styles.wrapper} ${className} ${errorMessage.length > 0 ? styles.error : ''}`}
    >
      <PhoneInput
        className={styles.input}
        id={id}
        placeholder={t('input.mobilenumber')}
        international
        countryOptionsOrder={['CH', 'FR', 'IT', 'DE', '|']}
        defaultCountry='CH'
        value={value as E164Number}
        onChange={handleChange}
        onBlur={handleBlur}
        countryCallingCodeEditable={false}
        labels={enroller !== undefined ? availableLocales[enroller.locale as keyof typeof availableLocales] : availableLocales[defaultLocale]}
      />
      <label
        htmlFor={id}
        className={styles.label}
      >
        {label}
      </label>
      {
        (required && value.length === 0 && focusOut) && (
          <Message
            type='warning'
            message={requiredMessage}
            className={styles.message}
          />
        )
      }
      {
        errorMessage && (
          <Message
            type='error'
            message={errorMessage}
            className={styles.message}
          />
        )
      }
    </div>
  );
}

export default RegistrationPhoneInput;
