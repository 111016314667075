import { ReactNode } from 'react';
import { formatPhoneNumberIntl } from 'react-phone-number-input';
import { useTranslation } from 'react-i18next';
import { E164Number } from 'libphonenumber-js';
import DisplayInfo from 'components/displayInfo/DisplayInfo';
import Title from 'components/content/Title';
import CardWrapper from 'components/layout/cardWrapper/CardWrapper';
import Stack from 'components/layout/stack/Stack';
import { dateStringToDateFormat } from '../../utils/date';
import { availableLocales, fallbackLng } from '../../services/EnrollmentForm';
import styles from './ViewUser.module.scss';

type User = {
  firstName?: string,
  lastName?: string,
  email?: string,
  pids?: Array<string>,
  birthDate?: string,
  mobileNumber?: string,
  street?: string,
  city?: string,
  country?: string,
  institutions?: string[],
  locale?: string,
};

interface Props {
  agoraID: string,
  user: User,
  hideLocal?: boolean,
  children?: ReactNode | null,
}

function ViewUser({
  agoraID,
  user,
  hideLocal = false,
  children = null,
}: Props) {
  const { t, i18n } = useTranslation();

  function findLocale() {
    const locale = availableLocales.find((availableLocale) => availableLocale.locale === user.locale);
    if (locale === undefined) {
      return availableLocales.find((availableLocale) => availableLocale.locale === fallbackLng);
    }
    return locale;
  }

  const locale = findLocale();

  return (
    <div className={styles.wrapper}>
      <div className={styles.container}>
        <Title title={t('user.informationspatient')} />
        <CardWrapper>
          <Stack
            width='medium'
            numberOfColumns='two-columns'
          >
            <DisplayInfo
              title={t('user.firstname')}
              value={user.firstName}
            />
            <DisplayInfo
              title={t('user.lastname')}
              value={user.lastName}
            />
            <DisplayInfo
              title={t('user.dateofbirth')}
              value={user.birthDate && dateStringToDateFormat(user.birthDate, i18n.language)}
            />
            {!hideLocal && (
            <DisplayInfo
              title={t('user.preferedlang')}
              value={locale?.nativeName}
            />
            )}
          </Stack>
        </CardWrapper>
      </div>
      <div className={styles.container}>
        <Title title={t('user.informationscontact')} />
        <CardWrapper>
          <Stack
            width='medium'
            numberOfColumns='two-columns'
          >
            <DisplayInfo
              title={t('user.email')}
              value={user.email}
            />
            <DisplayInfo
              title={t('user.mobilenumber')}
              value={user.mobileNumber && formatPhoneNumberIntl(user.mobileNumber as E164Number)}
            />
            <DisplayInfo
              title={t('user.maillingaddress')}
              value={[user.street, user.city, user.country]}
            />
          </Stack>
        </CardWrapper>
      </div>
      <div className={styles.container}>
        <Title title={t('user.identifiants')} />
        <CardWrapper>
          <Stack
            width='medium'
            numberOfColumns='two-columns'
          >
            <DisplayInfo
              title={t('user.pid')}
              value={user.pids}
            />
            <DisplayInfo
              title={t('user.titleagoracareid')}
              value={agoraID}
            />
          </Stack>
        </CardWrapper>
      </div>
      { children && (
      <div className={styles.container}>
        { children }
      </div>
      )}
    </div>
  );
}

export default ViewUser;
