import InputSelect, { Option } from 'components/input/InputSelect';
import { useTranslation } from 'react-i18next';
import { Validation, ValidationType } from 'api/institutionProxy';

type SelectValidationTypeProps = {
  label: string,
  id: string,
  value?: string,
  className?: string,
  required?: boolean,
  errorMessage?: string,
  onChange?: (value: ValidationType) => void,
}

function SelectValidationType({
  label,
  id,
  value = '',
  required = false,
  errorMessage = '',
  className = '',
  onChange,
}: SelectValidationTypeProps) {
  const { t } = useTranslation();

  const options: Option<ValidationType>[] = Validation.filter((validation) => validation !== '').map((validationType) => ({
    value: validationType,
    label: t(`registration.validationtype.type.${validationType}`),
  })) || [];

  return (
    <InputSelect
      id={id}
      label={label}
      value={value}
      onChange={onChange}
      className={className}
      required={required}
      errorMessage={errorMessage}
      options={options}
    />
  );
}

export default SelectValidationType;
