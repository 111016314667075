import { useEffect } from 'react';
import { UserManager } from 'oidc-client-ts';
import PageLoader from 'components/layout/pageLoader/PageLoader';
import IDENTITY_CONFIG from '../../constants/authConst';

function SigninCallback() {
  useEffect(() => {
    const callSigninRedirectCallback = async () => {
      try {
        await new UserManager({
          ...IDENTITY_CONFIG,
          response_mode: 'query',
        }).signinRedirectCallback();
        window.location.href = '/';
      } catch {
        window.location.href = '/';
      }
    };

    callSigninRedirectCallback();
  }, []);
  return (
    <PageLoader />
  );
}

export default SigninCallback;
