import { ReactElement } from 'react';
import navLinkStyles from './NavButton.module.scss';

export type NavigationButtonField = {
  icon?: ReactElement;
  name: string;
  onClick?: () => void;
}

interface NavButtonItemProps {
  navigationField: {
    icon?: React.ReactElement;
    name: string;
    onClick?: () => void;
  };
}

function NavButtonItem(props: NavButtonItemProps) {
  const { navigationField } = props;

  const handleOnClick = () => {
    if (navigationField?.onClick) {
      navigationField.onClick();
    }
  };

  return (
    <button
      type='button'
      className={navLinkStyles.wrapper}
      onClick={handleOnClick}
    >
      <div className={navLinkStyles.icon}>{navigationField?.icon}</div>
      {navigationField?.name}
    </button>
  );
}

export default NavButtonItem;
