import styles from './CardWrapper.module.scss';

interface CardWrapperProps {
  children: React.ReactNode[] | React.ReactNode;
}

function CardWrapper(props: CardWrapperProps) {
  const { children } = props;

  return (
    <div className={styles.wrapper}>
      {children}
    </div>
  );
}

export default CardWrapper;
