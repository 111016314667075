import { ChangeEvent } from 'react';
import styles from './InputRadio.module.scss';

type Props = {
  id: string,
  label: string,
  value: string,
  name: string,
  checked: boolean,
  onChange?: (value: string) => void,
  className: string,
}

function InputRadio({
  id,
  label,
  value,
  name,
  checked,
  className = '',
  onChange,
}: Props) {
  const hanldeChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (onChange !== undefined) {
      onChange(event.target.value);
    }
  };

  return (
    <div className={className}>
      <input
        className={`${styles.input} ${className}`}
        type='radio'
        id={id}
        name={name}
        value={value}
        checked={checked}
        onChange={hanldeChange}
      />
      <label className={styles.label} htmlFor={id}>{label}</label>
    </div>
  );
}

export default InputRadio;
