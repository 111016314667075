import { useTranslation } from 'react-i18next';
import Form from 'components/layout/form/Form';
import CardWrapper from 'components/layout/cardWrapper/CardWrapper';
import { Button } from '@agora-care/ui-components';
import ContentButton from '../content/ContentButton';
import styles from './SuccessRequest.module.scss';

interface Props {
  name: string,
  onSubmit?: () => void,
}

function SuccessRequest({
  name,
  onSubmit,
}: Props) {
  const { t } = useTranslation();

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (onSubmit !== undefined) {
      onSubmit();
    }
  };

  return (
    <div className={styles.wrapper}>
      <CardWrapper>
        <Form onSubmit={handleSubmit}>
          <h2>{t('useraffiliation.successmessage', { name })}</h2>
          <ContentButton>
            <Button type='submit'>
              {t('useraffiliation.ok')}
            </Button>
          </ContentButton>
        </Form>
      </CardWrapper>
    </div>
  );
}

export default SuccessRequest;
