import DateField from './DateField';
import InputFields from './InputFields';
import styles from './InputDate.module.scss';

interface Props {
  label: string,
  id: string,
  value?: string,
  errorMessage: string,
  onChange?: (value: string) => void,
  required?: boolean,
  className?: string,
}

function InputDate({
  errorMessage = '',
  id,
  label,
  value = '',
  onChange,
  required = false,
  className = '',
}: Props) {
  return (
    <div
      className={`${styles.wrapper} ${className}`}
    >
      <InputFields
        label={label}
        id={id}
        value={value}
        type='date'
        errorMessage={errorMessage}
        onChange={onChange}
        required={required}
      />
      <DateField
        value={value}
        className={styles['date-field']}
      />
    </div>
  );
}

export default InputDate;
