import React from 'react';
import InputSelect, { Option } from 'components/input/InputSelect';
import countriesList from 'assets/data/countryISO3166.json';
import { countryName } from 'utils/country';
import { EnrollerContext } from 'context/enroller/Enroller';

interface Props {
  label: string,
  id: string,
  value?: string,
  onChange?: (value: string) => void,
  className?: string,
  required?: boolean,
  errorMessage?: string,
}

function InputCountry({
  id,
  label,
  value = '',
  onChange,
  className = '',
  required = false,
  errorMessage = '',
}: Props) {
  const { enroller } = React.useContext(EnrollerContext);

  const favoritesCountries = [
    'CH',
    'FR',
    'IT',
    'DE',
  ];

  const handleChange = (selectedValue: string) => {
    if (onChange !== undefined) {
      onChange(selectedValue);
    }
  };

  const options: Option[] = (() => {
    if (enroller !== undefined) {
      const favorites: Option[] = favoritesCountries.map((iso) => ({
        value: iso,
        label: countryName(iso, enroller.locale),
      }));
      const countries: Option[] = countriesList.filter((iso) => !favoritesCountries.includes(iso)).map((iso) => ({
        value: iso,
        label: countryName(iso, enroller.locale),
      })).sort((a, b) => a.label.localeCompare(b.label));
      const space: Option = {
        value: '|',
        label: '',
      };

      return [...favorites, space, ...countries];
    }
    return [];
  })();

  return (
    <InputSelect
      id={id}
      label={label}
      value={value}
      onChange={handleChange}
      className={className}
      options={options}
      required={required}
      errorMessage={errorMessage}
      name='country'
    />
  );
}

export default InputCountry;
