import styles from './DisplayInfo.module.scss';

type Props = {
  title: string,
  value?: string | number | Array<string | undefined>,
}

function DisplayInfo({
  title,
  value,
}: Props) {
  return (
    <div className={styles.wrapper}>
      <span className={styles.secondary}>
        {title}
      </span>
      {Array.isArray(value) ? (
        <div className={styles.value}>
          {value.length > 0 ? value.map((v) => (
            <div key={v}>{v}</div>
          )) : '-'}
        </div>
      ) : (
        <span className={styles.value}>{value || '-'}</span>
      )}
    </div>
  );
}

export default DisplayInfo;
