import { useEffect, ReactNode } from 'react';
import {
  BrowserRouter as Router, Routes, Route,
} from 'react-router';
import DEFAULT_LOCALE from 'constants/locale';
import { SCOPE_STAFF } from '../../constants/authConst';
import SigninCallback from './SigninCallback';
import SilentRenew from './SilentRenew';

type Profile = {
  agoraID: string,
  institutionID: string,
  name: string | undefined,
  locale: string,
}

type Props = {
  children: ReactNode,
  onProfileLoaded: (profile: Profile) => void,
}

function OidcSignin(props: Props) {
  const { children, onProfileLoaded } = props;

  useEffect(() => {
    const initializeAgoraID = async (): Promise<void> => {
      if (
        window.authService !== undefined
        && !window.authService.isLoggedIn()
        && window.location.pathname !== '/signin-callback.html'
        && window.location.pathname !== '/silent-renew.html'
      ) {
        const userLoaded = await window.authService.loadUser();
        if (userLoaded === null || userLoaded.expired) {
          window.authService.login();
        } else {
          const { profile, scope } = userLoaded;
          if (
            scope === undefined
            || !scope.split(' ').includes(SCOPE_STAFF)
            || profile.institution === undefined
            || profile.agora_id === undefined
          ) {
            window.authService.logout();
          }
          onProfileLoaded({
            agoraID: profile.agora_id as string,
            institutionID: profile.institution as string,
            name: profile.name,
            locale: profile.locale || DEFAULT_LOCALE,
          });
        }
      }
    };

    initializeAgoraID();

    return () => {};
  }, []);

  return (
    <>
      <Router>
        <Routes>
          <Route
            path='/silent-renew.html'
            element={<SilentRenew />}
          />
          <Route
            path='/signin-callback.html'
            element={<SigninCallback />}
          />
        </Routes>
      </Router>
      {children}
    </>
  );
}

export default OidcSignin;
