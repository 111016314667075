import { useEffect, useRef, useState } from 'react';
import LogoutButton from 'components/navigation/logoutButton/LogoutButton';
import { ReactComponent as Arrow } from 'icons/ArrowUp.svg';
import styles from './MobileMenu.module.scss';

interface MobileMenuProps {
  currentUser: {
    fullName: string;
    agoraID: string;
    institutionID: string;
  };
}

function MobileMenu(props: MobileMenuProps) {
  const { currentUser } = props;
  const [isExpanded, setIsExpanded] = useState(false);
  const wrapperRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (wrapperRef.current && !wrapperRef.current.contains(event.target as Node)) {
        setIsExpanded(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <div
      className={styles.wrapper}
      onClick={() => setIsExpanded(!isExpanded)}
      onKeyDown={(event) => {
        if (event.key === 'Enter') {
          setIsExpanded(!isExpanded);
        }
      }}
      ref={wrapperRef}
      role='button'
      tabIndex={0}
    >
      <div className={styles['user-infos']}>
        <span className={styles.name}>{currentUser?.fullName}</span>
        <span className={styles['agora-id']}>{currentUser?.agoraID}</span>
        <div className={styles.arrow}>
          <Arrow />
        </div>
      </div>
      <div
        className={styles.menu}
        aria-expanded={isExpanded}
      >
        <LogoutButton />
      </div>
    </div>
  );
}

export default MobileMenu;
