import {
  useState, useEffect,
} from 'react';
import { useNavigate, useParams } from 'react-router';
import { UserRegistered, isTemporaryUser, loadUser } from 'components/user/UserRegistred';
import RegisteredViewUser from 'components/user/RegisteredViewUser';
import PageLoader from 'components/layout/pageLoader/PageLoader';

interface Props {
  institutionID: string,
}

function LayoutViewUser({ institutionID }: Props) {
  const navigate = useNavigate();
  const { agoraID } = useParams();
  const [loadingUser, setLoadingUser] = useState(true);
  const [user, setUser] = useState<UserRegistered>();

  const getUser = async (userAgoraID: string) => {
    try {
      const userLoaded = await loadUser(userAgoraID, institutionID);
      if (userLoaded !== undefined && !isTemporaryUser(userLoaded)) {
        setUser(userLoaded);
        setLoadingUser(false);
      } else {
        navigate('/');
      }
    } catch {
      navigate('/');
    }
  };

  useEffect(() => {
    if (agoraID !== undefined) {
      getUser(agoraID);
    } else {
      navigate('/');
    }
  }, []);

  return (
    (!loadingUser && user !== undefined && agoraID !== undefined) ? (
      <RegisteredViewUser
        agoraID={agoraID}
        institutionID={institutionID}
        user={user}
      />
    ) : (
      <PageLoader />
    )
  );
}

export default LayoutViewUser;
