import {
  ReactNode, useEffect, useRef,
} from 'react';
import { useTranslation } from 'react-i18next';
import styles from './Popup.module.scss';
import { ReactComponent as Close } from '../../icons/Agora-Add.svg';

export type PopupType = 'form' | 'alert';

type PopupProps = {
  onCancelClick?: () => void,
  className?: string,
  type?: PopupType,
  title?: string,
  icon?: ReactNode,
  children: ReactNode,
  isOpen: boolean,
};

function Popup({
  onCancelClick,
  className = '',
  children,
  type = 'form',
  title,
  icon,
  isOpen,
}: PopupProps) {
  const popupRef = useRef(null);
  const { t } = useTranslation();

  let headerClass: string = type;

  if (type === 'form') {
    headerClass = styles.form;
  } else if (type === 'alert') {
    headerClass = styles.alert;
  }

  useEffect(() => {
    const currentPopup = popupRef.current;

    const handleClickEvent = (evt: MouseEvent) => {
      if (
        onCancelClick !== undefined
        && currentPopup !== null
        && evt.target === currentPopup
      ) {
        onCancelClick();
      }
    };

    const handleKeydown = (evt: KeyboardEvent) => {
      if (
        onCancelClick !== undefined
        && evt.code === 'escape'
      ) {
        onCancelClick();
      }
    };

    window.addEventListener('click', handleClickEvent);
    window.addEventListener('keydown', handleKeydown);

    return () => {
      window.removeEventListener('click', handleClickEvent);
      window.removeEventListener('keydown', handleKeydown);
    };
  }, [onCancelClick]);

  if (!isOpen) {
    return null;
  }

  return (
    <div
      ref={popupRef}
      className={`${className} ${headerClass} ${styles['pop-up']}`}
    >
      <div className={styles.content}>
        {(title !== undefined) && (
          <header className={`${styles['popup-header']} ${icon ? styles['header-with-icon'] : ''}`}>
            {icon && icon}
            <div className={styles.title}>
              <span>{title}</span>
            </div>
            {(onCancelClick !== undefined && type !== 'alert') && (
              <button
                type='button'
                className={styles['close-icon']}
                onClick={onCancelClick}
                aria-label={t('registration.close')}
              >
                <Close />
              </button>
            )}
          </header>
        )}
        <main className={styles.main}>
          {children}
        </main>
      </div>
    </div>
  );
}

export default Popup;
