import React from 'react';
import { ReactComponent as DoubleArrowIcon } from 'icons/DoubleArrowIcon.svg';
import { availableLocales } from '../../services/EnrollmentForm';
import styles from './SelectLang.module.scss';

interface Props {
  label: string,
  id: string,
  value?: string,
  onChange?: (value: string) => void,
  className?: string,
}

function SelectLang({
  id,
  label,
  value = '',
  className = '',
  onChange,
}: Props) {
  const options = availableLocales.map((language) => (
    <option
      key={language.key}
      value={language.locale}
    >
      { language.nativeName }
    </option>
  ));

  const handleChange = (event: React.ChangeEvent<HTMLSelectElement>): void => {
    if (onChange !== undefined) {
      onChange(event.target.value);
    }
  };

  return (
    <div
      className={`${className} ${styles['select-field']}`}
    >
      <select
        name='languages'
        id='languages'
        onChange={handleChange}
        defaultValue={value}
      >
        { options }
      </select>
      <DoubleArrowIcon />
      <label
        htmlFor={id}
        data-testid='label-fields'
      >
        {label}
      </label>
    </div>
  );
}

export default SelectLang;
